<template>
  <div class="forget-password-page">
    <Header class="header" />
    <Form @submit="onSubmit">
      <!-- 输入账号 --> 
      <Field class="field" placeholder="请输入账号" v-model="account" :border="false" />

      <!-- 输入短信验证码 -->
      <Field class="field" placeholder="请输入验证码"  v-model="code" :border="false">
        <template #button>
          <span class="send-code" @click="onSendCode">{{leftTime ? `${leftTime}s后重新发送` : '发送验证码'}}</span>
        </template>
      </Field>

      <!-- 输入新密码 -->
      <Field class="field" placeholder="请输入新密码" v-model="password" type="password" :border="false" />

      <!-- 二次输入验证码 -->
      <Field class="field" placeholder="请再次输入新密码" v-model="confirmPassword" type="password" :border="false" />
      <Button class="submit-button" native-type="submit">提交</Button>
    </Form>
  </div>
</template>

<script>
import { sendCode, handleForgetPwd } from '@/api/login';
import { Form, Field, Button } from 'vant';
import Header from '../../components/header';

export default {
  components: {
    Form,
    Field,
    Header,
    Button,
  },
  data() {
    return {
      code: '',
      account: '',
      password: '',
      confirmPassword: '',
      leftTime: 0,
    }
  },
  methods: {
    /**
     * 发送短信验证码
     */
    async onSendCode() {
      if (!this.account) {
        return this.$toast('请输入账号');
      }
      if (!this.leftTime) {
        try {
          await sendCode({loginName: this.account});
          this.leftTime = 59;
          this.startCountDown();
        } catch (e) {
          this.$toast(e.message);
        }
      }
    },

    /**
     * 开启倒计时
     */
    startCountDown() {
      clearTimeout(this._timer);
      this._timer = setTimeout(() => {
        this.leftTime = Math.max(this.leftTime - 1, 0);
        this.startCountDown();
      }, 1000);
    },

    /**
     * 提交表单
     */
    async onSubmit() {
      if (this.password !== this.confirmPassword) {
        return this.$toast('两次密码输入不一致');
      }
      try {
        await handleForgetPwd({
          loginName: this.account,
          newPassword: this.password,
          validateLogin: this.code,
        });
        this.$toast('密码修改成功');
        setTimeout(() => this.$router.back(), 1000);
      } catch (e) {
        this.$toast(e.message);
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.forget-password-page {
  min-height: 100vh;
  background: #FAFBFC;
  font-size: 14px;
  .header {
    margin-bottom: 12px;
  }
  .field {
    padding: 8px 24px;
    margin-top: 6px;
    .send-code {
      font-size: 14px;
      color: #CD001F;
    }
  }
  .submit-button {
    width: 327px;
    height: 40px;
    text-align: center;
    line-height: 40px;
    font-size: 12px;
    color: #FFFFFF;
    background: #CD011F;
    box-shadow: 0px 0px 7px rgba(235, 235, 235, 0.62);
    border-radius: 10px;
    border: none;
    margin-top: 35px;
  }
}
</style>
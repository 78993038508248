import { post,login } from '@/utils/request';

export const handleLogin = async data => {
  return await post('/appdealer/api/user/h5login', data)
}

export const sendCode = async data => {
  return await post('/appdealer/api/user/get_code', data);
}

export const handleForgetPwd = async data => {
  return await post('/appdealer/api/user/forget_pswd', data);
}

export const fetchOpenId = async data => {
  return await login('/wechat/jsapi/get_openid', data);
}

export const handleChangePassword = async data => {
  return await post('/appdealer/api/user/modify_pswd', data);
}

export const handleSmsLogin = async data => {
    return await post('/appdealer/api/user/h5PhoneLogin', data)
}

export const handleGetCode = async data => {
    return await post('/appdealer/api/user/get_code', data)
}

